import Footer from '../Footer/Footer';
import HeaderAd from '../HeaderAd/HeaderAd';
import Hero from '../Hero/Hero';
import Navbar from '../Navbar/Navbar';
import OurServices from '../OurServices/OurServices';
import Ready from '../Ready/Ready';
import WhyChoose from '../WhyChoose/WhyChoose';
import './Homepage.css';

function Homepage () {
    

    return (
        <div className='page home'>
            <Navbar />
            <Hero />
            <HeaderAd />
            <OurServices />
            <WhyChoose 
                    heading='Why Choose eNVie LLC?' 
                    img='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_4.png?v=1713815727'
                    bullet1='Reliable'
                    bullet1Text='We pride ourselves on the reliability and quality of our services and products.'                    
                    bullet2='Efficient'
                    bullet2Text='Quick and efficient service delivery that respects your time.'
                    bullet3='Flexible'
                    bullet3Text='Customizable service packages tailored to your specific requirements.'
                    bullet4='Affordable'
                    bullet4Text='Competitive pricing to ensure you get the best value for your money.'
                />
            <Ready />
            <Footer />
        </div>
    );
}

export default Homepage;
import './ContactInfo.css';
import { contactInfo } from '../../data/data';

function ContactInfo () {
    return (
        <ul className='contact-info'>

            <li>
            <a href={'mailto:' + contactInfo.email}>E: {contactInfo.email}</a>
            </li>
            <li>
            <a href={'tel:' + contactInfo.phone}>P: {contactInfo.phoneString}</a>

            </li>
            
        </ul>
    )
}

export default ContactInfo;
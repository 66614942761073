const contactInfo = {
    email: 'support@enviegroup.co',
    phone: 5615936113,
    phoneString: '(561) 593-6113'
};

const logos = {
    logoMD: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/envie_1.png?v=1713819759'
}

const ourServices = [
    { 
        title: 'Car rental',
        description: `Choose from a large selection of vehicles to suit any occasion or need. Whether you're looking for a compact car for city driving or a spacious SUV for a family trip, we have you covered.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_cars.png?v=1713813160',
        link: 'car-rental'
    },
    { 
        title: 'Student Furniture Rental',
        description: `Furnishing your college room or apartment has never been easier. We offer affordable, stylish furniture packages perfect for students.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-student.png?v=1713814402',
        link: 'furniture-rental'
    },
    { 
        title: 'Packers & Movers',
        description: `Moving can be hectic, but our professional packers and movers make it seamless. Trust us to handle your belongings with care.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-mover.png?v=1713814376',
        link: ''
    },
    { 
        title: 'Events & Party Rentals',
        description: `From corporate events to intimate gatherings, we provide all the essentials you need — tents, chairs, tables, and more.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-party.png?v=1713814317',
        link: ''

    },
    { 
        title: 'Staging & Decor',
        description: `Choose from a large selection of vehicles to suit any occasion or need. Whether you're looking for a compact car for city driving or a spacious SUV for a family trip, we have you covered.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-decor.png?v=1713814347',
        link: 'staging'

    },
    { 
        title: 'Professional Technicians',
        description: `Need installation or repair services? Our skilled technicians are ready to assist with all your technical needs.`,
        img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-tech.png?v=1713814330',
        link: ''

    },
];



const mobileMenu = [
    {
        item: 'Home', link: '/',
    },
    {
        // item: 'Car rental', link: '/car-rental',
    },
];

export { contactInfo, logos, ourServices, mobileMenu };
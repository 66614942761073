import CarRentalFleet from '../CarRentalFleet/CarRentalFleet';
import Navbar from '../Navbar/Navbar';
import './CarRental.css';
import Footer from '../Footer/Footer';
import WhyChoose from '../WhyChoose/WhyChoose';
import HeaderAd from '../HeaderAd/HeaderAd';

function CarRental () {
    return (
        <div>
            <Navbar />
            <div className='page car-rental'>
                <section className='page-hero car-rental--hero'>
                    <div className='page-hero--cta'>
                            <h2 style={{fontSize: '1.5rem'}}>car rental</h2>
                            <h2>Your journey, <strong>our wheels</strong></h2>
                            <a href='#fleet' className='btn--main'>See fleet →</a>        
                    </div>
                </section>
                <HeaderAd />
                

                <section className='car-rental--intro'>
                    <div className='car-rental--intro--grid'>
                        <div className='car-rental-intro--right'>
                            <h3>Your Adventure Starts Here</h3>
                            <img style={{maxWidth: '300px', marginTop: '1rem'}} src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_18.png?v=1716400840' />
                        </div>
                        <div className='car-rental-intro--left'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_17.png?v=1716400732' />
                            <p>At eNVie LLC, we believe that every trip should be memorable and hassle-free. That's why we offer a diverse fleet of well-maintained vehicles to suit all your travel needs</p>
                        </div>

                    </div>
                </section>
                <div id='fleet'></div>
                <CarRentalFleet  />
                <section className='pickup'>
                    <div className='pickup--grid'>
                        <div className='pickup--left'>
                            <h3>Pickup and Drop-Off Details</h3>
                            <p>We offer flexible pickup and drop-off options to suit your schedule</p>
                            <ul>
                                <li>
                                    <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_location.png?v=1716828689' />
                                    <div>
                                        <h4>Car Location Pickup</h4>
                                        <p>Meet us at the specified location for a handover.</p>

                                    </div>
                                </li>
                                <li>
                                    <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_location_1.png?v=1716830532' />
                                    <div>
                                        <h4>Airport Pickup</h4>
                                        <p>Enjoy the convenience of airport pickup. We’ll have the car ready with a lockbox code.</p>

                                    </div>
                                </li>
                                <li>
                                    <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_location_2.png?v=1716830671' />
                                    <div>
                                        <h4>Airport Drop-Off</h4>
                                        <p>Drop off your rental at the airport. We take care of the rest!</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='pickup--right'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_19.png?v=1716567321' />
                        </div>

                    </div>
                </section>

                <WhyChoose 
                    heading='Why Choose eNVie LLC Car Rental?' 
                    img='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Add_a_heading.png?v=1717433946'
                    bullet1='Reliable Vehicles'
                    bullet1Text='At eNVie LLC, we pride ourselves on offering well-maintained vehicles to suit all your travel needs. '                    
                    bullet2='Competitive Pricing'
                    bullet2Text='Enjoy top-notch vehicles at an affordable price, leaving you with more budget for your adventure.'
                    bullet3='Hassle-Free Booking'
                    bullet3Text='No hidden fees, no complicated procedures – just a straightforward way to book the car you need.'
                    bullet4='Outstanding Support'
                    bullet4Text='From the moment you book to the time you return the vehicle, our dedicated team is here to assist you. '
                />

                <section className='book-car'>
                    <div className='book-car--grid'>
                        <div className='book-car--left'>
                            <img style={{width: '100%', maxWidth: '300px'}} src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_21.png?v=1716573118' />
                            <img style={{width: '100%'}} src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_20.png?v=1716573016' />
                        </div>
                        <div className='book-car--right'>
                            <h3>Book Your Car Today!</h3>
                            <p style={{marginTop: '.5rem'}}>Don't wait to secure the perfect rental for your trip. At eNVie LLC, we're committed to providing you with a reliable, comfortable, and enjoyable driving experience. </p>
                            <a style={{marginTop: '.5rem'}} href='#fleet' className='btn--secondary'>See fleet</a>
                        </div>

                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default CarRental;
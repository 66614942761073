import { Link } from 'react-router-dom';
import './FurnitureRentalPackage.css';

function FurnitureRentalPackage() {
    const rentalPackages = [
        {
            title: 'Basic',
            price: '$109/mo',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_30.png?v=1717691507',
            detail: `
                <p><strong>Living room</strong></p>
                <p>Sofa, Coffee Table, Floor Lamp</p>
                <p><strong>Bedroom</strong></p>
                <p>Headboard, Mattress Set, Chest, Nightstand, Lamp</p>
                <p><strong>Dining Area</strong></p>
                <p>2 Barstools</p>
            `
        },
        {
            title: 'All-in-One Studio',
            price: '$149/mo',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_31.png?v=1717691508',
            detail: `
                <p><strong>Living room</strong></p>
                <p>Sofa, Coffee Table, Floor Lamp</p>
                <p><strong>Bedroom</strong></p>
                <p>Headboard, Mattress Set, Chest, Nightstand, Lamp</p>
                <p><strong>Dining Room</strong></p>
                <p>Dining Table, 2 Dining Chairs</p>
            `
        },
        {
            title: 'Royal Treatment',
            price: '$209/mo',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_32.png?v=1717691507',
            detail: `
                <p><strong>Living room</strong></p>
                <p>Sofa, Chair, Coffee & End Table, Lamp</p>
                <p><strong>Bedroom</strong></p>
                <p>Headboard, Mattress Set, Dresser & Mirror, Nightstand, Lamp</p>
                <p><strong>Dining Room</strong></p>
                <p>Dining Table, 4 Dining Chairs</p>
            `
        },
        {
            title: 'Designed by Me',
            price: 'Custom pricing',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_33.png?v=1717691507',
            detail: `
                <p>We understand that everyone’s needs and tastes are different. That’s why we offer a customizable package that allow you to select specific items or add extras to your rental plan, ensuring you get exactly what you want.</p>
            `
        },

    ];

    return (
        <div className='packages--grid'>
            {
                rentalPackages.map(({title,img, price, detail}) => {
                    return (
                        <article className='packages--card'>
                            <img src={img} />
                            <h4 className='packages--title'>{title}</h4>
                            <p className='packages--price'>{price}</p>
                            <div style={{marginTop: '1rem'}} dangerouslySetInnerHTML={{__html: detail}}></div>
                            <Link to='/contact' className='btn--main packages--book'>Book now</Link>
                        </article>
                    )
                })
            }
        </div>

    )
}

export default FurnitureRentalPackage;
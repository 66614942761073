import './OurServices.css';
import { ourServices } from '../../data/data';
import { Link } from 'react-router-dom';

function OurServices () {

    

    return (
        <section className='our-services'>
            <h3>Our Services</h3>
            <div className='services--container'>
                {
                    ourServices.map(({title, description, img, link}) => {
                        return <Link to={link} className='service--container'>
                                <div className='service--container--info'>
                                    <h4>{title}</h4>
                                    <p>{description}</p>
                                </div>
                                <img src={img} />
                            </Link>
                    })
                }
            </div>
        </section>
    );
}

export default OurServices;
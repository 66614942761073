import { useState } from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './Contact.css';
import { ourServices } from '../../data/data';
import ContactInfo from '../ContactInfo/ContactInfo';

function Contact () {
    const [customerName, setCustomerName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [result, setResult] = useState('');

    const submitHandler = async (e) => {
        e.preventDefault();
        setResult("Sending....");

        const content = {
            'Customer name': customerName,
            email,
            phone,
            'service required': service,
            message
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
        "access_key": "d36a8d1f-73bb-470e-9293-cb02ebfd0655",
        "subject" : "[eNVie LLC] New message received",
        ...content
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch("https://api.web3forms.com/submit", requestOptions)
        .then((response) => response.text())
        .then((result) => setResult("✓ Thank you! Someone from our team will contact you shortly."))
        .catch((error) => setResult(error))
        .finally(() => setIsSubmitted(true));

    
    }

    return (
        <div>
            <Navbar />
            <section className='page contact'>
                <div className='contact--container'>
                    <div className='contact--left'>
                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_6.png?v=1713880630' />
                    </div>
                    <div className='contact--right'>
                        <h2>Let's Work Together!</h2>
                        {
                            isSubmitted 
                            ? <p className='success'> {result}</p>
                            : <form onSubmit={submitHandler}>
                            <label>Your Name *
                                <input required type='text' onChange={(e) => setCustomerName(e.target.value)} />
                            </label>
                            <label>Email *
                                <input required type='email' onChange={(e) => setEmail(e.target.value)} />
                            </label>
                            <label>Phone 
                                <input type='number' onChange={(e) => setPhone(e.target.value)} />
                            </label>
                            <label>Service required *
                                <select required onChange={(e) => setService(e.target.value)}>
                                    <option value=''>--</option>
                                    {
                                        ourServices.map((i) => {
                                            return (
                                                <option value={i.title}>{i.title}</option>
                                            )
                                        })
                                    }
                                    <option value='others'>Others</option>
                                </select>
                            </label>
                            <label>Message *
                                <textarea required type='text' onChange={(e) => setMessage(e.target.value)} />
                            </label>
                            <button className='btn--main'>Submit</button>
                        </form>
                        }
                        <ContactInfo />
                        
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Contact;
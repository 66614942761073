import './WhyChoose.css';

function WhyChoose ({heading, img, bullet1, bullet1Text, bullet2, bullet2Text,bullet3, bullet3Text,bullet4, bullet4Text}) {
    const whyUs1 = [
        {
            title: 'Reliable',
            description: `We pride ourselves on the reliability and quality of our services and products.`
        },
        {
            title: 'Flexible',
            description: `Customizable service packages tailored to your specific requirements.`
        }
    ];

    const whyUs2 = [
        {
            title: 'Efficient',
            description: `Quick and efficient service delivery that respects your time.`
        },
        {
            title: 'Affordable',
            description: `Competitive pricing to ensure you get the best value for your money.`
        }
    ];

    return (

        <section className='why'>
            <h3>{heading}</h3>
            <div className='cards--container'>
                <div className='cards--left'>
                    <div className='card--container'>
                        <h4>{bullet1}</h4>
                        <p>{bullet1Text}</p>
                    </div>
                    <div className='card--container'>
                        <h4>{bullet2}</h4>
                        <p>{bullet2Text}</p>
                    </div>
                    
                    
                </div>
                <img src={img} />
                <div className='cards--right'>
                    <div className='card--container'>
                            <h4>{bullet3}</h4>
                            <p>{bullet3Text}</p>
                        </div>
                        <div className='card--container'>
                            <h4>{bullet4}</h4>
                            <p>{bullet4Text}</p>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChoose;
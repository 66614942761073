import logo from './logo.svg';
import './App.css';
import Homepage from './components/Homepage/Homepage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './components/Contact/Contact';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import CarRental from './components/CarRental/CarRental';
import FurnitureRental from './components/FurnitureRental/FurnitureRental';
import Staging from './components/Staging/Staging';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' >
            <Route index element={<Homepage />} />
            <Route path='contact' element={<Contact />} />
            <Route path='car-rental' element={<CarRental />} />
            <Route path='furniture-rental' element={<FurnitureRental />} />
            <Route path='staging' element={<Staging />} />

          </Route>
        </Routes> 
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;

import './Navbar.css';
import { logos } from '../../data/data';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import Dropdown from '../Dropdown/Dropdown';

function Navbar () {
    const [isShowingDropdown, setIsShowingDropdown] = useState(false);
    const [isShowingServices, setIsShowingServices] = useState(false);
    const onToggle = () => {
        setIsShowingDropdown(!isShowingDropdown);
    }
    const handleClick = (e) => {
        if (e.target.className !== 'dropdown') setIsShowingDropdown(false);
    }

    return (
        <div className='navbar'>
            <div className='navbar--container'>
                <div className='logo--container'>
                    <Link to='/'>
                        <img src={logos.logoMD} />
                    </Link>
                </div>
                <nav className='navbar--right' >
                    <menu className='navbar--menu'>   
                        <div className='desktop-menu'>
                            <li>
                                <Link to='/'>
                                    Home
                                </Link>
                            </li>     
                            <li>
                                <div className='services-menu' style={{position: 'relative'}} onMouseEnter={() => setIsShowingServices(true)} onMouseLeave={() => setIsShowingServices(false)}>
                                    Services
                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="#000000"/>
                                    </svg>
                                    {
                                        isShowingServices  
                                        ? <ul className='service-dropdown'>
                                            <li>
                                                <NavLink className={({ isActive }) => isActive ? "active-navbar" : ""}  to='/car-rental'>
                                                    Car rental
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={({ isActive }) => isActive ? "active-navbar" : ""}  to='/furniture-rental'>
                                                    Student furniture rental
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={({ isActive }) => isActive ? "active-navbar" : ""}  to='/staging'>
                                                    Staging and decor
                                                </NavLink>
                                            </li>
                                        </ul>

                                        : <ul className='service-dropdown collapsed'>
                                        
                                        
                                        </ul>
                                        
                                    }
                                    

                                </div>
                            </li>
                            <li >
                                <Link className='btn--secondary' to='/contact'>
                                    Get in touch
                                </Link>
                            </li>
                        </div>
                    </menu>
                    <button onClick={onToggle} className='hamburger-btn'>
                        {
                            isShowingDropdown
                            ? <svg width="32px" height="32px" viewBox="0 0 24 24" version="1.1" >
                                <title>Close</title>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Close">
                                        <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24">
                            
                                </rect>
                                            <line x1="16.9999" y1="7" x2="7.00001" y2="16.9999" id="Path" stroke="#0C0310" stroke-width="2" stroke-linecap="round">
                                
                                </line>
                                            <line x1="7.00006" y1="7" x2="17" y2="16.9999" id="Path" stroke="#0C0310" stroke-width="2" stroke-linecap="round">
                                
                                </line>
                                        </g>
                                    </g>
                                </svg>
                            : <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Menu / Menu_Alt_05">
                            <path id="Vector" d="M5 17H13M5 12H19M11 7H19" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                        }
                    </button>
                    
                </nav>

            </div>

            
                {
                    isShowingDropdown
                    && <div onClick={handleClick} className='transition-all dropwdown--wrapper'>
                        <Dropdown />
                    </div>
                    
                }

       
                {/* <TopBannerAd /> */}
        </div>
    )
}

export default Navbar;
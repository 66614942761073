import './Hero.css';

function Hero () {
    const heroImages = [
        'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve.png?v=1713809944',
        'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_1.png?v=1713810401',
        'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_2.png?v=1713811992',
        'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_3.png?v=1713812034'
    ]; 

    return (
        <section className='hero'>
            <div className='hero--container'>
                <div className='title--container'>
                    <h2>
                        <span>
                            Skip the <strong>Stress, </strong>
                        </span>
                        <span>
                            We'll <strong>Handle</strong> the Rest

                        </span>
                    </h2>
                </div>
                <div className='img--container'>
                    {
                        heroImages.map((i) => {
                            return <img src={i} />
                        })
                    }

                </div>
                <div className='hero--text'>
                    <p>
                        Make life easier with our wide range of rental and professional services. Choose eNVie LLC for stress-free, tailored solutions.
                    </p>
                </div>

            </div>
            </section>
    );
}

export default Hero;
import './StagingPackages.css';
import { Link } from 'react-router-dom';

function StagingPackages () {
    const rentalPackages = [
        {
            title: 'Basic',
            price: '',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_43.png?v=1718896696',
            detail: `
                <p><strong>Bedroom Set: $100/month</strong></p>
                <p>Includes: 1 Queen Size Bed (or 2 Twin beds), 1 Night Stand Table</p>
                <p>Optional Add-Ons: 1 Dresser ($30), 1 Lamp ($15)</p>
                <p><strong>Dining Room Set: $80/month</strong></p>
                <p>Includes: 1 Table, 4 Chairs</p>
                <p><strong>Living Room Set: $120/month</strong></p>
                <p>2 Includes: 1 Sofa, 1 Chair, 1 End Table, 1 Coffee Table, 1 Floor Lamp</p>
            `
        },
        {
            title: 'Chic Contemporary',
            price: '',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_44.png?v=1718896950',
            detail: `
                <p>This package offers the same items as the Basic category but with a modern twist, perfect for those looking to achieve a contemporary aesthetic.</p>
                <br>
                <p><strong>Bedroom Set: $120/month</strong></p>
                <p><strong>Dining Room Set: $100/month</strong></p>
                <p><strong>Living Room Set: $125/month</strong></p>
               
            `
        },
        {
            title: 'Opulence',
            price: '',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_45.png?v=1718906129',
            detail: `
                
                <p>For those who desire luxury, the Opulence package includes the same items as the Basic and Chic Contemporary categories but with premium finishes and a touch of extravagance.</p>
                <br>
                <p><strong>Bedroom Set: $150/month</strong></p>
                <p><strong>Dining Room Set: $130/month</strong></p>
                <p><strong>Living Room Set: $160/month</strong></p>
            `
        },
        {
            title: 'Poolside',
            price: '',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_46.png?v=1718906229',
            detail: `
                <p>Our Poolside package is perfect for creating an inviting outdoor oasis. It includes everything you need to enjoy leisure time by the pool with comfort and style.</p>
                <br>
                <p><strong>Price: $150/month</strong></p>
                <p>Includes: 2 Pool Beds, a Grill, 4 Plants, an Umbrella, 2 Ottomans</p>

            `
        },
        {
            title: 'Custom',
            price: '',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_47.png?v=1718906307',
            detail: `
                
                <p>Do you have specific items in mind that aren't listed in our packages? No problem!</p>
                <br>
                <p><strong>At eNVie LLC, we offer customized staging and decor solutions.</strong></p>
                <p><strong>   Simply request a quote, and we'll work with you to bring your vision to life.</strong></p>
               
            `
        },

    ];

    return (
        <div className='packages--grid'>
            {
                rentalPackages.map(({title,img, price, detail}) => {
                    return (
                        <article className='packages--card'>
                            <img src={img} />
                            <h4 className='packages--title'>{title}</h4>
                            <p className='packages--price'>{price}</p>
                            <div style={{marginTop: '1rem'}} dangerouslySetInnerHTML={{__html: detail}}></div>
                            <Link to='/contact' className='btn--main packages--book'>Get started ➜</Link>
                        </article>
                    );
                })
            }
        </div>

    )
}

export default StagingPackages;
import Footer from '../Footer/Footer';
import FurnitureRentalPackage from '../FurnitureRentalPackage/FurnitureRentalPackage';
import HeaderAd from '../HeaderAd/HeaderAd';
import Navbar from '../Navbar/Navbar';
import StagingPackages from '../StagingPackages/StagingPackages';
import WhyChoose from '../WhyChoose/WhyChoose';
import './Staging.css';

function Staging () {
    const howItWorks = [
        {   
            num: '01',
            heading: 'Choose Your Package',
            text: 'Browse through our selection of packages and choose the one that best fits your needs and style.'
        },
        {   
            num: '02',
            heading: 'Customize Your Selection',
            text: 'Some packages might come with optional add-ons, allowing you to customize your furniture and decor to perfectly match your vision.'
        },
        {   
            num: '03',
            heading: 'Schedule Delivery Date',
            text: 'Our team will handle all the logistics to ensure your furniture and decor arrive on time and in perfect condition.'
        },
    ];

    return (
        <div>
            <Navbar />
            <div className='page staging'>
                <section className='page-hero staging--hero'>
                        <div className='page-hero--cta'>
                                <h2 style={{fontSize: '1.5rem'}}>Staging & Decor</h2>
                                <h2>Make Every Room a <strong> Masterpiece</strong></h2>
                                <a href='#packages' className='btn--main'>See our packages →</a>        
                        </div>
                    </section>

                <HeaderAd />

                <section className='furniture-rental--intro'>
                    <div className='furniture-rental--intro--grid container'>
                        <div className='furniture-rental--intro--left'>
                            <h3>Experience the Art of Home Staging</h3>
                            <p>At eNVie LLC, we understand the power of a beautifully staged home. Our Staging and Decor services are designed to elevate your living spaces, making them not only functional but also visually stunning.  </p>
                           
                        </div>
                        <div className='furniture-rental--intro--right'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_39.png?v=1718893395' />
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_40.png?v=1718893668' />
                        </div>
                    </div>
                </section>

                <section className='furniture-rental--how'>
                    <div className='furniture-rental--how--grid container'>
                        <div className='furniture-rental--how--left'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_41.png?v=1718893767' />
                        </div>
                        <div className='furniture-rental--how--right'>
                            <h3>How it works</h3>
                            <div className='how--cards'>
                                {
                                    howItWorks.map(({num, heading, text}) => {
                                        return (
                                            <article className='how--card'>
                                                <p className='how--number'>{num}</p>
                                                <div>
                                                    <h4>{heading}</h4>
                                                    <p>{text}</p>

                                                </div>
                                            </article>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </section>
                
                <div id='packages'></div>
                <section className='furniture-rental--packages'>
                    <div className='container'>
                        <div style={{maxWidth: '900px'}}>
                            <h3>Discover Our Furniture Packages</h3>
                            <p>Our carefully curated packages cater to a variety of styles and budgets. Whether you're looking to enhance the appeal of your home for a sale or simply want to refresh your interiors, we've got you covered.</p>

                        </div>
                        <StagingPackages />
                    </div>
                </section>

                <WhyChoose 
                    heading='Why Choose eNVie LLC Staging and Decor Service' 
                    img='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_42.png?v=1718895707'
                    bullet1='Quality'
                    bullet1Text='We provide high-quality furnishings that enhance the beauty and functionality of your space.'                    
                    bullet2='Flexibility'
                    bullet2Text='Our range of packages ensures there’s something to fit every style and budget.'
                    bullet3='Cost-Effective'
                    bullet3Text='Our rental packages are designed to be budget-friendly, offering you great furniture options at a fraction of the cost.'
                    bullet4='Convenience'
                    bullet4Text='Easy monthly rentals with optional add-ons to suit your needs.'
                />

                <section style={{marginTop: '5rem'}} className='furniture-rental--upgrade'>
                    <div className='furniture-rental--upgrade--grid container'>
                        <div className='furniture-rental--upgrade--left'>
                            <h3>Create the Home of Your Dreams</h3>
                            <p>Transform your space with eNVie LLC’s Staging and Decor services. Contact us now to choose the perfect package for your needs or to request a custom quote. Let’s make your home a true reflection of your style and elegance.</p>
                            <a style={{marginTop: '2.5rem', margin: 'auto'}} href='#packages' className='btn--main'>Get a quote</a>
                        </div>
                        
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Staging;
import './Dropdown.css';
import { mobileMenu } from '../../data/data';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';

function Dropdown () {
    return (
        <div className='dropdown'>
                <MobileMenu />
            </div>
    )
}

export default Dropdown;
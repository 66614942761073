import { Link } from 'react-router-dom';
import ContactInfo from '../ContactInfo/ContactInfo';
import './Ready.css';

function Ready () {
    return (
        <section className='ready'>
            <div className='ready--container'>
                <div className='ready--left'>
                    <h3>Ready to Start?</h3>
                    <p style={{marginTop: '2rem'}}>Contact us today for a quote or to discuss your needs!</p>
                    <Link to='/contact' className='btn--main cta'>Get in touch</Link>
                    <ContactInfo />
                </div>

                <div className='ready--right'>
                    <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve-cta.png?v=1713817873' />
                </div>

            </div>
        </section>
    )
}

export default Ready;
import AdGenerator from '../AdGenerator/AdGenerator';
import './FooterAd.css';

function FooterAd () {
    return (
        <div className='footer-ad'>   
            <AdGenerator />

        </div>
    );
}

export default FooterAd;
import Footer from '../Footer/Footer';
import FurnitureRentalPackage from '../FurnitureRentalPackage/FurnitureRentalPackage';
import HeaderAd from '../HeaderAd/HeaderAd';
import Navbar from '../Navbar/Navbar';
import WhyChoose from '../WhyChoose/WhyChoose';
import './FurnitureRental.css';

function FurnitureRental () {
    const howItWorks = [
        {   
            num: '01',
            heading: 'Choose Your City',
            text: 'Select your location from our available cities.'
        },
        {   
            num: '02',
            heading: 'Select Your Package',
            text: 'Pick the package that best suits your needs.'
        },
        {   
            num: '03',
            heading: 'Schedule Delivery Date',
            text: 'Book a convenient delivery date and time.'
        },
    ];

    return (
        <div>
            <Navbar />
            <div className='page furniture-rental'>
                <section className='page-hero furniture-rental--hero'>
                        <div className='page-hero--cta'>
                                <h2 style={{fontSize: '1.5rem'}}>STUDENT FURNITURE RENTAL</h2>
                                <h2>From Campus to<strong> Comfort</strong></h2>
                                <a href='#packages' className='btn--main'>See rental packages →</a>        
                        </div>
                    </section>

                <HeaderAd />

                <section className='furniture-rental--intro'>
                    <div className='furniture-rental--intro--grid container'>
                        <div className='furniture-rental--intro--left'>
                            <h3>Simplify Your Move</h3>
                            <p>At eNVie LLC, we understand that moving into an off-campus apartment can be stressful and expensive. </p>
                            <p>Why spend time and money buying furniture, arranging for delivery, and then selling everything at the end of the semester? </p>
                            <p>Avoid the hassle and choose from our convenient and affordable rental packages.</p>
                        </div>
                        <div className='furniture-rental--intro--right'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_25.png?v=1717605961' />
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_27.png?v=1717606097' />
                        </div>
                    </div>
                </section>

                <section className='furniture-rental--how'>
                    <div className='furniture-rental--how--grid container'>
                        <div className='furniture-rental--how--left'>
                            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_29.png?v=1717612559' />
                        </div>
                        <div className='furniture-rental--how--right'>
                            <h3>How it works</h3>
                            <div className='how--cards'>
                                {
                                    howItWorks.map(({num, heading, text}) => {
                                        return (
                                            <article className='how--card'>
                                                <p className='how--number'>{num}</p>
                                                <div>
                                                    <h4>{heading}</h4>
                                                    <p>{text}</p>

                                                </div>
                                            </article>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </section>
                
                <div id='packages'></div>
                <section className='furniture-rental--packages'>
                    <div className='container'>
                        <div style={{maxWidth: '900px'}}>
                            <h3>Discover Our Furniture Packages</h3>
                            <p>Make your new place feel like home with our custom-designed furniture packages. Our expert team will furnish three cohesive rooms just for you, allowing you to stay focused on what matters most – your education.</p>

                        </div>
                        <FurnitureRentalPackage />

                    </div>
                </section>

                <WhyChoose 
                    heading='Why Choose eNVie LLC Student Furniture Rental' 
                    img='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_34.png?v=1717692867'
                    bullet1='Stylish and Cohesive Design'
                    bullet1Text='Our experienced team will curate a stylish and cohesive furniture set that perfectly fits your space and needs.'                    
                    bullet2='Hassle-Free Delivery and Setup'
                    bullet2Text='Our team handles the delivery  of your furniture, allowing you to focus on settling into your new home.'
                    bullet3='Cost-Effective'
                    bullet3Text='Our rental packages are designed to be budget-friendly, offering you great furniture options at a fraction of the cost.'
                    bullet4='Flexible Rental Terms'
                    bullet4Text='We offer flexible rental terms to accommodate your needs, whether you’re staying for a semester or longer.'
                />

                <section style={{marginTop: '5rem'}} className='furniture-rental--upgrade'>
                    <div className='furniture-rental--upgrade--grid container'>
                        <div className='furniture-rental--upgrade--left'>
                            <h3>Upgrade Your Space, Focus on Study</h3>
                            <p>Choosing eNVie LLC means choosing convenience, affordability, style, and peace of mind. Let us help you create the perfect living space without the usual hassles and headaches of moving and furnishing your home.</p>
                            <a style={{marginTop: '2.5rem', margin: 'auto'}} href='#packages' className='btn--main'>See rental packages</a>
                        </div>
                        
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default FurnitureRental;
import './MobileMenu.css';
import { Link, NavLink } from 'react-router-dom';

function MobileMenu () {

    const mobileMenu = [
        {
            item: 'Home',
            link: '/'
        },
        {
            item: 'Car Rental',
            link: '/car-rental'
        },
        {
            item: 'Student Furniture Rental',
            link: '/furniture-rental'
        },
        {
            item: 'Staging & Decor',
            link: '/staging'
        },
    ]

    return (
        <ul className='mobile-menu'>
                {
                    mobileMenu.map(({item, link}) => {
                        return (
                            <li>
                                <NavLink className={({ isActive }) => isActive ? "active-navbar" : ""} to={link}>
                                    {item}
                                </NavLink>
                            </li>
                        )
                    })
                }
                <li>
                    <Link className='btn--secondary' to='/contact'>
                        Get in touch
                    </Link>
                </li>
            </ul>
    )
}

export default MobileMenu;
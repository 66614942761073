import './Footer.css';
import { logos } from '../../data/data';
import { Link } from 'react-router-dom';
import ContactInfo from '../ContactInfo/ContactInfo';
import MobileMenu from '../MobileMenu/MobileMenu';
import FooterAd from '../FooterAd/FooterAd';

function Footer () {
    
    return (
        <div>
            <footer className='footer'>
                <div className='footer--logo'>
                    <Link to='/'>
                        <img src={logos.logoMD} />
                    </Link>
                </div>
                <MobileMenu />
                <ContactInfo />
                <div className='footer--credit'>
                    <p>© eNVie LLC 2024</p>

                </div>
            </footer>
            <FooterAd />
        </div>
    )
}

export default Footer;
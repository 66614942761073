import { Link } from 'react-router-dom';
import './CarRentalFleet.css';

function CarRentalFleet () {
    const carListings = [
        {
            car: 'Dodge Charger 2014',
            price: '$36/day',
            location: 'Fort Lauderdale, FL 33315, Miami International Airport, Palm Beach International Airport',
            thumbnail: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Dodge_Charger_2014.png?v=1716566284',
            link: 'https://earn.sng.link/A3ir4p/llnh?_dl=uberdriver://web/bonjour/marketplace/marketplaces/vehicles_us/earnings_share/product/0399266e-d99c-580e-8fee-ba2522476faf/offer/9232b5c9-6693-534b-83e7-c0ad0c7607ab?_gl=11ft2bp8_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_smtype=3&_fallback_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/0399266e-d99c-580e-8fee-ba2522476faf/offer/9232b5c9-6693-534b-83e7-c0ad0c7607ab?_gl=11ft2bp8*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=11ft2bp8*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_ios_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/0399266e-d99c-580e-8fee-ba2522476faf/offer/9232b5c9-6693-534b-83e7-c0ad0c7607ab?_gl=11ft2bp8*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=11ft2bp8*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_android_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/0399266e-d99c-580e-8fee-ba2522476faf/offer/9232b5c9-6693-534b-83e7-c0ad0c7607ab?_gl=11ft2bp8*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=11ft2bp8*_gcl_au*MTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared'
        },
        {
            car: 'Honda Civic 2021',
            price: '$37/day',
            location: 'Fort Lauderdale, FL 33315, Miami International Airport, Palm Beach International Airport',
            thumbnail: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/honda_civic_2021.png?v=1716566445',
            link: ' https://earn.sng.link/A3ir4p/llnh?_dl=uberdriver://web/bonjour/marketplace/marketplaces/vehicles_us/earnings_share/product/d56b5ae6-1e39-5c7b-85a0-0f5db3857813/offer/67d2b797-69c6-50a0-a72f-0d51288b0ddf?_gl=1y312d5_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=&vs_ref_id=driver_shared&_smtype=3&_fallback_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/d56b5ae6-1e39-5c7b-85a0-0f5db3857813/offer/67d2b797-69c6-50a0-a72f-0d51288b0ddf?_gl=1y312d5*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=?_gl=1y312d5*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=&vs_ref_id=driver_shared&_ios_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/d56b5ae6-1e39-5c7b-85a0-0f5db3857813/offer/67d2b797-69c6-50a0-a72f-0d51288b0ddf?_gl=1y312d5*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=?_gl=1y312d5*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=&vs_ref_id=driver_shared&_android_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/d56b5ae6-1e39-5c7b-85a0-0f5db3857813/offer/67d2b797-69c6-50a0-a72f-0d51288b0ddf?_gl=1y312d5*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=?_gl=1y312d5*_gcl_au*MTI0MjIwMTU5My4xNzE5OTUyMjU4&_csid=ERUGtEToZ4OcgQk3qN3acg&state=UOLlJ-Jn0KAetlnzz1dPohicBgmnFq5a9NIdL1Rmr8U%3D&effect=&vs_ref_id=driver_shared'
        },
        {
            car: 'Toyota Camry 2024',
            price: '$57/day',
            location: 'Miami, FL 33142, Miami International Airport, Fort Lauderdale-Hollywood International Airport, Palm Beach International Airport, Mangonia Park',
            thumbnail: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Toyota_Camry_2024.png?v=1716566299',
            link: 'https://earn.sng.link/A3ir4p/llnh?_dl=uberdriver://web/bonjour/marketplace/marketplaces/vehicles_us/earnings_share/product/c4c88401-18ff-5b63-a781-95f964d4f47b/offer/59e3cef6-183e-5308-aff3-fdf8cb65f978?_gl=1fburfi_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_smtype=3&_fallback_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/c4c88401-18ff-5b63-a781-95f964d4f47b/offer/59e3cef6-183e-5308-aff3-fdf8cb65f978?_gl=1fburfi*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=1fburfi*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_ios_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/c4c88401-18ff-5b63-a781-95f964d4f47b/offer/59e3cef6-183e-5308-aff3-fdf8cb65f978?_gl=1fburfi*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=1fburfi*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared&_android_redirect=https://bonjour.uber.com/marketplace/marketplaces/vehicles_us/earnings_share/product/c4c88401-18ff-5b63-a781-95f964d4f47b/offer/59e3cef6-183e-5308-aff3-fdf8cb65f978?_gl=1fburfi*_gcl_auMTI0MjIwMTU5My4xNzE5OTUyMjU4?_gl=1fburfi*_gcl_au*MTI0MjIwMTU5My4xNzE5OTUyMjU4&vs_ref_id=driver_shared'

        },
    ];

    return (
        <section className='car-rental--fleet'>
            <div className='car-rental--fleet--heading'>
                <h3>
                    Explore Our Fleet
                </h3>
                <p style={{marginTop: 'auto'}}>Whether you’re on vacation or here for business, our reliable cars and convenient pickup locations ensure a smooth and enjoyable ride.</p>
            </div>

            <div className='car-listings'>
                {
                    carListings.map(({car,price,location, thumbnail, link}) => {
                        return (
                            <article className='car-listing'>
                                <div className='car-listing--left'>
                                    <h4 className='car-listing--title'>
                                        <Link to="/contact">
                                            {car}
                                        </Link>
                                    </h4>
                                    <p className='car-listing--price'>{price}</p>
                                    <div className='car-listing--location'>
                                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/websbysteve_location.png?v=1716828689' />
                                        <p>{location}</p>
                                    </div>
                                    <Link className='car-listing--book-now btn--main' to="/contact">Book now</Link>
                                </div>
                                <Link to="/contact"  className='car-listing--right'>
                                    <img className='car-listing--thumbnail' src={thumbnail} />
                                </Link>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default CarRentalFleet;